.user-profile {
    margin-top: 90px;
}

.card {
    box-shadow: rgba(9, 30, 66, 0.4) 0px 1px 3px;
    margin-bottom: 24px;
    padding: 16px 24px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
}

.user-profile .label-text {
    margin-bottom: 0px !important;
}

.user-profile .profile-text {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    display: block;
    color: rgb(28, 27, 27);
}

.profile-box {
    padding: 24px;
    border: 1px solid rgb(237, 237, 246);
    border-radius: 5px;
    max-width: 290px;
    height: 300px;
    margin: 0 auto;
}

.profile-box img {
    height: 250px;
    width: 100%;
    object-fit: contain;
}

